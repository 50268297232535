import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Seo } from '~/models/seo';
import { Articles } from '~/models/articles';
import { Employee } from '~/models/employee';
import { Services } from '~/models/services';

export namespace ArticleElements {
	export const type = {
		DEFAULT: 'default', // Текстовый блок
		SUB_CONTENT_BLOCK: 'subContentBlock', // Блок с вложенным содержанием
		WHITE_BLOCK: 'whiteBlock', // Блок с белой подложкой
		GRAY_BLOCK: 'grayBlock', // Блок с серой подложкой
		ALSO_READ_BLOCK: 'alsoReadBlock', // Блок читайте также
		IMAGE_BLOCK: 'imageBlock', // Блок изображение
		EMPLOYEES_BLOCK: 'employeesBlock', // Блок сотрудники
		SERVICES_BLOCK: 'servicesBlock', // Блок услуги
		ADVANTAGES_BLOCK: 'advantagesBlock', // Блок преимуществ
	} as const;

	export type BlockType = (typeof type)[keyof typeof type];

	// Element types
	export type Name = string | null;
	export type Header = string | null;
	export type Content = string | null;
	export type SubContent = {
		style: 'default' | 'white' | 'gray';
		header: Header;
		content: Content;
	};
	export type Advantage = {
		title: string;
		accent: string;
		text: string;
	};

	export namespace Block {
		// Default
		export type Default = {
			type: typeof type.DEFAULT;
			header: Header;
			content: Content;
		};

		// SubContentBlock
		export type SubContentBlock = {
			type: typeof type.SUB_CONTENT_BLOCK;
			header: Header;
			content: Content;
			subContent: SubContent[];
		};

		// WhiteBlock
		export type WhiteBlock = {
			type: typeof type.WHITE_BLOCK;
			header: Header;
			content: Content;
		};

		// GrayBlock
		export type GrayBlock = {
			type: typeof type.GRAY_BLOCK;
			header: Header;
			content: Content;
		};

		// AlsoReadBlock
		export type AlsoReadBlock = {
			type: typeof type.ALSO_READ_BLOCK;
			articleLink: { alias: Articles.Model['alias']; seo: { header: Seo.Model['header'] } };
		};

		// ImageBlock
		export type ImageBlock = {
			type: typeof type.IMAGE_BLOCK;
			image: Image.Model;
		};

		// EmployeesBlock
		export type EmployeesBlock = {
			type: typeof type.EMPLOYEES_BLOCK;
			header: Header;
			content: Content;
			employees: Employee.Model[];
		};

		// ServicesBlock
		export type ServicesBlock = {
			type: typeof type.SERVICES_BLOCK;
			header: Header;
			content: Content;
			services: Services.Model[];
		};

		// AdvantagesBlock
		export type AdvantagesBlock = {
			type: typeof type.ADVANTAGES_BLOCK;
			header: Header;
			content: Content;
			advantages: Advantage[];
		};
	}

	export type Model = Common.Model &
		(
			| Block.Default
			| Block.SubContentBlock
			| Block.WhiteBlock
			| Block.GrayBlock
			| Block.AlsoReadBlock
			| Block.ImageBlock
			| Block.EmployeesBlock
			| Block.ServicesBlock
			| Block.AdvantagesBlock
		) & {
			name: Name;
		};

	export const modelState: Model = {
		...Common.modelState,
		name: null,
		type: type.DEFAULT,
		header: null,
		content: null,
	};
}
