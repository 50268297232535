<template lang="pug">
Container
	h1 {{ header }}

	div(class='Articles')
		NuxtLink(
			v-for='(article, index) in articlesPaginationItems',
			:key='index',
			class='Article',
			:to='getArticlesPageUrl(article.alias)'
		)
			UiImage(:image='article.preview', width='400px', lazy)
			div(class='Date') {{ $f.formatDate(article.date) }}
			div(v-if='article.seo', class='Name') {{ article.seo.header }}

	div(class='ArticlesPagination')
		UiButton(
			v-if='!isFirstPage',
			class='Pagination--Button',
			variant='secondary',
			rounded,
			square,
			paddet,
			size='xl',
			iconLeft='system/arrow-left',
			iconLeftSize='md',
			@click='goToPreviousPage'
		)
		span {{ pageId }} / {{ articlesPagination.length }}
		UiButton(
			v-if='!isLastPage',
			class='Pagination--Button',
			variant='secondary',
			rounded,
			square,
			size='xl',
			iconRight='system/arrow-right',
			iconRightSize='md',
			@click='goToNextPage'
		)
Container
	UnitConsultation(type='employees')

UiSubscribe
</template>
<script lang="ts" setup>
import { joinURL } from 'ufo';
import { Articles } from '~/models/articles';
import { Resolver } from '~/models/resolver';

defineComponent({
	name: 'ArticlesPage',
});

/**
 * Define hooks
 */
const { header } = useMeta();
const { articlesState, fetchArticles } = useArticles();
const { getPageUrlByType, fetchResolver } = useResolver();

const route = useRoute();
const router = useRouter();

/**
 * Get articles page url by alias
 */
const getArticlesPageUrl = (alias: string) =>
	joinURL(getPageUrlByType(Resolver.Page.ARTICLES) || '', alias);

/**
 * Page id
 */
const pageId = computed(() => Number(route.query.page) || 1);

/**
 * Split articlesState by 6 items to page
 */
const articlesPagination = computed(() =>
	articlesState.value.reduce((acc, article, index) => {
		if (index % 6 === 0) {
			acc.push([]);
		}
		acc[acc.length - 1].push(article);
		return acc;
	}, [] as Articles.Model[][])
);

/**
 * Articles by pageId
 */
const articlesPaginationItems = computed(() => articlesPagination.value[pageId.value - 1] || []);

/**
 * Is first page
 */
const isFirstPage = computed(() => pageId.value === 1);

/**
 * Is last page
 */
const isLastPage = computed(() => pageId.value === articlesPagination.value.length);

/**
 * Go to next page
 */
const goToNextPage = () => {
	if (
		articlesPagination.value[pageId.value] &&
		articlesPagination.value[pageId.value].length > 0
	) {
		router.push({ query: { page: pageId.value + 1 } });
		scrollToTop();
	}
};

/**
 * Go to previous page
 */
const goToPreviousPage = () => {
	if (pageId.value - 1 === 1) {
		router.push({ query: { page: undefined } });
		scrollToTop();
	} else if (articlesPagination.value[pageId.value - 1].length > 0) {
		router.push({ query: { page: pageId.value - 1 } });
		scrollToTop();
	}
};

/**
 * Scroll to top after page change
 */
const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 * Define fetch
 */
await fetchArticles();
await fetchResolver();
</script>

<style lang="less" scoped>
.Articles {
	padding: 2rem 0 2rem 0;
	.box(grid; 100%; auto; none; 2rem; auto; 1fr; center; start);

	& > .Article {
		text-decoration: none;
		.box(grid; 100%; 100%; none; 0.5rem; auto auto 1fr; 1fr; stretch; start);
		& > img {
			object-fit: cover;
			.grid-aspect(4.4; 2.2);
			.box(block; 100%; 100%);
			.border-radius(@BorderRadiusSmall);
		}
		& > .Date {
			.grid(1 span);
			.text(fade(@ColorBase, 50%); 1.25em 0.875rem @medium);
		}
		& > .Name {
			.grid(1 span);
			.text(@ColorBase; 1.4em 1.125rem @medium; left; none none);
		}
	}

	@media all and (min-width: @tablet-small) and (max-width: (@laptop - 1px)) {
		.box(grid; 100%; auto; none; 3rem 2rem; auto; repeat(2, 1fr); center; start);
	}
	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; none; 4rem 2rem; auto; repeat(3, 1fr); center; start);
	}
}

.ArticlesPagination {
	padding: 2rem 0 2rem 0;
	.box(inline-grid; auto; auto; none; 2rem; 1fr; auto; center; center; column);
	&--Button {
		.grid(1 span);
	}
	& > span {
		.grid(1 span);
		.text(@ColorBase; 1.4em 1.125rem @medium; left; none none);
	}
}
</style>
