<template lang="pug">
div(class='SliderServicesEmployees')
	UiCarousel(
		class='SliderServicesEmployees--Carousel',
		:perView='greaterThan("tablet") ? 4 : greaterThan("mobileWide") ? 3 : 1.8',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(
					v-for='employee in publishedEmployees',
					:key='employee.id',
					v-bind='carouselItemProps'
				)
					div(class='SliderServicesEmployees--Carousel--Item')
						div(class='Employee')
							NuxtLink(:to='getArticlesPageUrl(employee.alias)', class='Photo')
								UiImage(:image='employee.photo', width='320px', height='420px', lazy)
							NuxtLink(:to='getArticlesPageUrl(employee.alias)', class='Name') {{ employee.name }} {{ employee.surname }}
	div(
		v-if='greaterThan("tablet") && publishedEmployees.length > 4',
		class='SliderServicesEmployees--CarouselNavigation'
	)
		UiButton(
			class='SliderServicesEmployees--CarouselNavigation--Button',
			:class='{ Disabled: activeSlide === 1 }',
			variant='secondary',
			rounded,
			square,
			paddet,
			size='xl',
			iconLeft='system/arrow-left',
			iconLeftSize='md',
			@click='slidePrev'
		)
		UiButton(
			class='SliderServicesEmployees--CarouselNavigation--Button',
			:class='{ Disabled: isEndSlide }',
			variant='secondary',
			rounded,
			square,
			size='xl',
			iconRight='system/arrow-right',
			iconRightSize='md',
			@click='slideNext'
		)
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';
import { Common } from '~/models/common';
import { Employee } from '~/models/employee';
import { Resolver } from '~/models/resolver';
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);
const activeSlide = computed(() => slider.value?.activeSlide || 1);
const isEndSlide = computed(() => slider.value?.isEndSlide || false);
const slideNext = () => slider.value?.slideNext();
const slidePrev = () => slider.value?.slidePrev();

/**
 * Define props
 */
const { employees = [] } = defineProps<{
	employees: Employee.Model[];
}>();

/**
 * Published employees
 */
const publishedEmployees = computed(() =>
	employees.filter(e => e.status === Common.Status.PUBLISHED)
);

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { getPageUrlByType, fetchResolver } = useResolver();

/**
 * Get employees page url by alias
 */
const getArticlesPageUrl = (alias: string) =>
	joinURL(getPageUrlByType(Resolver.Page.EMPLOYEES) || '', alias);

/**
 * Define fetch
 */
await fetchResolver();
</script>

<style lang="less" scoped>
.SliderServicesEmployees {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; center);

	&--Carousel {
		.grid(1 span; 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			padding: 1rem 0 0 0;
			margin: -1rem 0 0 0;
			.box(grid; 100%; 100%; none; 0; 1fr; 1fr; stretch; start);
			& > .Employee {
				.box(grid; 100%; auto; none; 0.625rem; auto 1fr; 1fr; start; start);
				& > .Photo {
					.relative(1);
					.grid(1 span);
					.box(flex; 100%; auto; none; flex-start; center; center; nowrap row);
					.border-radius(@BorderRadiusMedium);
					.transition(all; 250ms; 'sine');
					&:before {
						content: '';
						.absolute(1; none; 3rem 0 0 0);
						.box(block; 100%; auto; @ColorWhite);
						.border-radius(@BorderRadiusMedium);
						.box-shadow(0 0 0 0 fade(@ColorBase, 25%));
						.transition(all; 250ms; 'sine');
					}
					& > img {
						.relative(2);
						object-fit: cover;
						.box(block; 100%; 100%);
						.border-bottom-radius(@BorderRadiusMedium);
					}
					&:hover {
						.translateY(-0.1875rem);
						&:before {
							.box-shadow(0 1.5rem 3rem -1.5rem fade(@ColorBase, 25%));
						}
					}
				}
				& > .Name {
					.grid(1 span);
					.text(@ColorBase; 1.25em 0.875rem @medium; center; none none);
					.transition(all; 250ms; 'sine');
					&:hover {
						.text(@ColorPrimary);
					}
				}
			}
		}
	}

	&--CarouselNavigation {
		padding: 1rem 0;
		.box(grid; auto; auto; none; 2rem; 1fr; auto; center; center; column);
		&--Button {
			padding: 1rem !important;
			.grid(1 span);

			&.Disabled {
				border-color: transparent;
				background: fade(@ColorBase, 5%);
				.cursor(default);
				.box-shadow(0; 0; 0; 0; transparent) !important;
			}
		}
	}
}
</style>
